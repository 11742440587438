import PropTypes from 'prop-types'
import React from 'react'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import MuiPaper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import MuiTextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'
import DialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiList from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import SaveIcon from '@mui/icons-material/Save'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import sourcesApi from '../../store/objects/sourcesAPI'
import apiHelper from '../../store/objects/apiHelper'
import FORMULAS from '../../store/objects/data/formulas'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setFormulaValuesAction } from '../../store/pages/common/formulaDialog'

const REGEX_MATCH = '(\\()(?!.*\\1).*'

const CHECK_AGGREGATORS = [
  'sum(',
  'min(',
  'max(',
  'average(',
  'count('
]

const DEFINITIONS = FORMULAS.map(def => ({
  ...def,
  cachedName: def.name.toLowerCase()
}))

const Paper = styled(MuiPaper)(({
  backgroundColor: '#F7F6F6'
}))

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr'
}))

const List = styled(MuiList)(({
  overflowY: 'scroll',
  height: 300,
  width: '100%'
}))

const TextField = styled(MuiTextField)(({
  width: '100%',
  backgroundColor: '#FFFFFF'
}))

const filterEnable = (items, query) => {
  return items.map(item => ({
    ...item,
    disabled: !item.cachedName.startsWith(query)
  }))
}

const validName = (showName, value) => (
  !showName || value
)

const validAggregators = (forbidAggregators, hasAggregators) => (
  !forbidAggregators || !hasAggregators
)

const regexMatching = value => {
  try {
    const matchedStr = value.match(REGEX_MATCH)

    const manipulated = matchedStr
      ? matchedStr[0].replace('(', '').replace(')', '')
      : value

    return manipulated
      ? manipulated.match('[^,]*$')[0]
      : ''
  } catch (err) {
    return ''
  }
}

const balanceParentheses = formula => {
  const openParen = (formula.match(/\(/g) || []).length
  const closeParen = (formula.match(/\(/g) || []).length

  return openParen === closeParen
}

const Grid = props => (
  <div style={{
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: props.columns
  }}>{props.children}</div>
)

Grid.propTypes = {
  columns: PropTypes.string,
  children: PropTypes.node
}

Grid.defaultProps = {
  columns: '1fr 1fr',
  children: null
}

const FormulaAdornment = props => {
  const handleValidateClick = () => {
    props.onValidate()
  }

  if (props.validating) {
    return (
      <CircularProgress
        data-testid='adornment-progress'
      />
    )
  }

  switch (props.valid) {
    case true:
      return (
        <CheckCircleIcon
          data-testid='adornment-icon-check'
          fontSize='large'
          style={{ color: '#6FE52A' }}
        />
      )

    case false:
      return (
        <Tooltip
          data-testid='adornment-tooltip'
          title={props.validationMessage}
        >
          <ErrorIcon
            data-testid='adornment-icon-error'
            color='primary'
            fontSize='large'
          />
        </Tooltip>
      )

    case null:
      return (
        <Button
          data-testid='adornment-button-validate'
          variant='outlined'
          onClick={handleValidateClick}
        >Validate</Button>
      )
  }
}

FormulaAdornment.propTypes = {
  valid: PropTypes.bool,
  validating: PropTypes.bool,
  validationMessage: PropTypes.string,
  onValidate: PropTypes.func
}

export default function FormulaDialog (props) {
  const dispatch = useDispatch()
  const formulaTextfieldRef = React.useRef(null)
  const [valid, setValid] = React.useState(null)
  const [validating, setValidating] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [validationMessage, setValidationMessage] = React.useState('')
  const [selectedDefinition, setSelectedDefinition] = React.useState(null)
  const [defHistory, setDefHistory] = React.useState([])

  const {
    name,
    formula
  } = useSelector(({
    formulaDialog: {
      name,
      formula
    }
  }) => ({
    name,
    formula
  }))

  const usingProject = Boolean(props.resource.projectName)
  const showName = usingProject && !props.forbidAggregators
  const cachedFormula = formula.toLowerCase()

  const formattedColumns = React.useMemo(() =>
    props.columnNames.map(name => ({
      name,
      cachedName: name.toLowerCase()
    })),
  [props.columnNames])

  const [filteredColumns, filteredDefinitions] = React.useMemo(() => [
    filterEnable(formattedColumns, search),
    filterEnable(DEFINITIONS, search)
  ], [
    search,
    formattedColumns
  ])

  const hasAggregators = Boolean(
    CHECK_AGGREGATORS.find(check =>
      cachedFormula.includes(check) &&
      !cachedFormula.includes('partition by')
    )
  )

  const canSave =
    valid &&
    balanceParentheses(formula) &&
    validName(showName, name) &&
    validAggregators(props.forbidAggregators, hasAggregators)

  const tooltipTitle = valid
    ? 'Succeeded'
    : 'ERROR: Please review your formula.'

  const definitionInfo = selectedDefinition
    ? [
        `Name: ${selectedDefinition.name}`,
        `Description: ${selectedDefinition.description}`,
        `Example: ${selectedDefinition.example}`
      ].join('\n')
    : ''

  const setField = (key, value) => {
    dispatch({
      ...setFormulaValuesAction,
      payload: { key, value }
    })
  }

  const resetState = () => {
    setValid(null)
    setValidating(false)
    setSearch('')
    setValidationMessage('')
    setSelectedDefinition(null)
    setDefHistory([])
    setField('name', '')
    setField('formula', '')
  }

  const filterDescription = value => {
    const lastString = regexMatching(value).toLowerCase()
    const lastChar = value.charAt(value.length - 1)

    setValid(null)
    setValidationMessage('')
    setSearch(lastString)

    const def = lastString
      ? filteredDefinitions.find(def =>
        def.cachedName.startsWith(lastString))
      : null

    setSelectedDefinition(def)

    if (lastChar === '(') {
      setDefHistory(prev => [
        ...prev,
        selectedDefinition
      ])
    } else if (lastChar === ')' && defHistory.length) {
      const updatedHistory = defHistory.slice(0, defHistory.length)
      const lastChange = updatedHistory[updatedHistory.length - 1]

      setSelectedDefinition(lastChange)
      setDefHistory(updatedHistory)
      setSearch('')
    }
  }

  const handleTextfieldChange = event => {
    const { name, value } = event.target

    setField(name, value)

    if (name === 'formula') {
      filterDescription(value)
    }
  }

  const handleValidate = async () => {
    const { country } = props.resource
    const { region } = apiHelper.getCountry(country)

    const fields = [
      {
        label: formula,
        value: formula
      }
    ]

    setValidating(true)

    try {
      sourcesApi.setAPIRegion(region)

      await sourcesApi.querySourceValidation(
        props.resource.id,
        props.tableName,
        fields,
        props.usePrecalculation
      )

      setValid(true)
    } catch (err) {
      const message = err.message || 'Syntax error in formula'

      setValidationMessage(message)
      setValid(false)
    }

    setValidating(false)
  }

  const handleItemClick = event => {
    const hasFormula = event.target.getAttribute('data-formula') === 'true'
    const name = event.target.getAttribute('data-name')
    const lastString = regexMatching(cachedFormula)
    const result = [
      name.replace(lastString, '').split('(')[0],
      hasFormula ? '(' : ''
    ].join('')

    dispatch({
      ...setFormulaValuesAction,
      payload: {
        key: 'formula',
        value: formula + result
      }
    })

    formulaTextfieldRef.current.focus()
  }

  const handleCancelClick = () => {
    resetState()
    props.onCancel()
  }

  const handleSaveClick = () => {
    props.onSave({ name, formula })
    resetState() /* only reset state AFTER onSave() is called */
  }

  return (
    <Paper>
      <DialogContent>
        {showName
          ? (
          <div>
            <Typography
              data-testid='label-name'
              variant='h6'
            >
              Name<Icon color='primary'>*</Icon>
            </Typography>

            <TextField
              data-testid='textfield-name'
              fullWidth
              autoFocus
              name='name'
              color='secondary'
              variant='outlined'
              value={name}
              onChange={handleTextfieldChange}
            />
          </div>
            )
          : <div></div>
        }

        <div>
          <Typography variant='h6'>
            Formula<Icon color='primary'>*</Icon>
          </Typography>

          <TextField
            data-testid='textfield-formula'
            fullWidth
            multiline
            error={valid === false}
            name='formula'
            color='secondary'
            variant='outlined'
            minRows={2}
            value={formula}
            inputRef={formulaTextfieldRef}
            onChange={handleTextfieldChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <FormulaAdornment
                    validating={validating}
                    valid={valid}
                    validationMessage={validationMessage}
                    onValidate={handleValidate}
                  />
                </InputAdornment>
              )
            }}
          />
        </div>

        <Grid>
          <div>
            <Typography variant='h6'>Columns</Typography>

            <List>
              {filteredColumns.map((column, index) => (
                <ListItem
                  data-testid={`item-column-${index}`}
                  data-name={column.name}
                  key={index}
                  button
                  disabled={column.disabled}
                  onClick={handleItemClick}
                >{column.name}</ListItem>
              ))}
            </List>
          </div>

          <div>
            <Typography variant='h6'>Formulas</Typography>

            <List>
              {filteredDefinitions.map((def, index) => (
                <ListItem
                  data-testid={`item-definition-${index}`}
                  data-name={def.name}
                  data-formula='true'
                  key={index}
                  button
                  disabled={def.disabled}
                  onClick={handleItemClick}
                >{def.name}</ListItem>
              ))}
            </List>
          </div>
        </Grid>

        <div>
          <Typography variant='h6'>Column / Function Information</Typography>

          <TextField
            data-testid='textfield-info'
            fullWidth
            multiline
            readOnly
            minRows={5}
            color='secondary'
            variant='outlined'
            value={definitionInfo}
          />
        </div>

        {props.forbidAggregators && hasAggregators && (
          <Typography data-testid='label-warning'>
            Note: Cannot use aggregator functions on this page
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          data-testid='button-cancel'
          color='primary'
          variant='outlined'
          onClick={handleCancelClick}
        >CANCEL</Button>

        <br/>

        <Tooltip title={tooltipTitle}>
          <span>
            <Button
              data-testid='button-save'
              disabled={!canSave}
              color='secondary'
              variant='contained'
              startIcon={<SaveIcon />}
              onClick={handleSaveClick}
            >SAVE</Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Paper>
  )
}

FormulaDialog.propTypes = {
  usePrecalculation: PropTypes.bool,
  forbidAggregators: PropTypes.bool,
  tableName: PropTypes.string,
  resource: PropTypes.shape({
    id: PropTypes.number,
    country: PropTypes.string,
    projectName: PropTypes.string
  }),
  columnNames: PropTypes.arrayOf(PropTypes.string),
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}

FormulaDialog.defaultProps = {
  usePrecalculation: false,
  forbidAggregators: false,
  tableName: '',
  resource: null,
  columnNames: [],
  onSave: () => {},
  onCancel: () => {}
}
