import { client } from './apiHelper'

const getReleaseData = () => {
  return client.get('/admin/release?type=release')
}

const getAnnouncementData = () => {
  return client.get('/admin/release?type=announcement')
}

// TODO: Split up this api call into two seperate calls for PUT and POST to make it more readable

const putAdminData = (body) => {
  const { type, guid } = body
  const method = guid ? 'put' : 'post'

  return client[method](`/admin/release?type=${type}`, body)
}

const removeAdminData = (body) => {
  return client.delete(`/admin/release?type=${body.type}&guid=${body.guid}`, body)
}

export default {
  putAdminData,
  getAnnouncementData,
  getReleaseData,
  removeAdminData
}
