import React from 'react'
import Fab from '@mui/material/Fab'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import InfoIcon from '@mui/icons-material/Info'
import ReleaseHistory from './ReleaseHistory'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material'

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(20),
  left: theme.spacing(2)
}))

export default function MenuInfo () {
  const [open, setOpen] = React.useState(false)
  const [openReleaseHistory, setOpenReleaseHistory] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const releaseData = useSelector((store) => store.adminTool.releaseData)

  const handleFabClicked = event => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleMenuClosed = () => {
    setOpen(false)
  }

  const handleReleaseItemClicked = () => {
    setOpen(false)
    setOpenReleaseHistory(true)
  }

  const handleReleaseHistoryClosed = () => {
    setOpenReleaseHistory(false)
  }

  return releaseData && (
    <div data-testid="content">
      <StyledFab
        data-testid="button-menu"
        size="small"
        color='secondary'
        label="Info Menu"
        onClick={handleFabClicked}
      >
        <InfoIcon />
      </StyledFab>

      <Menu
        data-testid="menu"
        keepMounted
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            border: 'solid grey',
            backgroundColor: '#efefef'
          }
        }}
        onClose={handleMenuClosed}
      >
        <MenuItem
          data-testid="item-release"
          onClick={handleReleaseItemClicked}
        >Releases</MenuItem>

        <ReleaseHistory
          data={releaseData}
          open={openReleaseHistory}
          onClose={handleReleaseHistoryClosed}
        />
      </Menu>
    </div>
  )
}
