import { styled } from '@mui/material'
import MuiGrid from '@mui/material/Grid'
import MuiTypography from '@mui/material/Typography'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearLoadedQueries } from '../../store/pages/dataPrep'
import QueryDrawer from './components/QueryDrawer'
import useLoadPrepData from './hooks/useLoadPrepData'
import { determineMessage } from '../../commonFunc'

const Grid = styled(MuiGrid)(({
  width: 'auto',
  minHeight: 400
}))

const Typography = styled(MuiTypography)(({ theme }) => ({
  margin: theme.spacing(2)
}))

function Prep () {
  useLoadPrepData()
  const dispatch = useDispatch()

  const navigationFile = useSelector(state => state.navigation.file)
  const dataPrepHideForm = useSelector(state => state.dataPrep.hideForm)
  const projectStudyType = useSelector(state => state.project.studyType)
  const projectFiles = useSelector(state => state.project.files)

  const isSkipMap = React.useMemo(() => projectStudyType === 'skipMap', [projectStudyType])

  const message = determineMessage(projectFiles, '/prep')

  React.useEffect(() => {
    return () => {
      dispatch(clearLoadedQueries())
    }
  }, [dispatch])
  if (!navigationFile) return null;
  return (
    <Grid
      data-testid='container'
      container
    >
      <Grid>
        {dataPrepHideForm || isSkipMap
          ? <Grid>
            <Typography
              data-testid='label-message'
              component="h2"
              variant="h5"
            >{message}</Typography>
          </Grid>
          : <QueryDrawer
              data-testid='query-drawer'
            />
        }
      </Grid>
    </Grid>
  )
}

export default React.memo(Prep)
