import { client } from './apiHelper'
import axios from 'axios'

const {
  REACT_APP_RGAENV: rgaEnv
} = process.env

let __region = process.env.REACT_APP_REGION

const setAPIRegion = region => {
  __region = region
}

const getProject = (projectId) => {
  return client.get(`/project/${projectId}`, { region: __region })
}

const postProject = body => {
  return client.post('/project', body, { region: __region })
}

const putProject = project => {
  const opts = {
    ...project,
    s3upload: undefined,
    profileData: undefined,
    fileData: undefined
  }

  return client.put(`/project/${project.id}`, opts, { region: __region })
}

const deleteProject = (id) => {
  return client.delete(`/project/${id}`, {}, { parseAsText: true })
}

const getProjectList = (order, orderBy, page, rowsPerPage, searchText) => {
  const pageQuery = page >= 0 && rowsPerPage > 0 ? `pageNo=${page + 1}&pageSize=${rowsPerPage}` : ''
  const sortQuery = order && orderBy ? `sortColumn=${orderBy}&sortOrder=${order}` : ''

  const projectList = client.get(`/project?${pageQuery}&${sortQuery}`)

  return projectList
}

const getNamesLdap = async (text = '') => {
  const isLowerEnv = ['poc', 'sdlc'].includes(rgaEnv)
  const url = `https://dapper.red${isLowerEnv ? '-np' : ''}.rgare.net/v1/ldap/query`
  const response = await axios.post(url, {
    filter: `(givenName=${text}*)`,
    attributes: ['sn', 'givenName', 'sAMAccountName', 'name', 'mail']
  })
  return response.data ? response.data.filter(x => x.mail) : []
}

export default {
  getProject,
  deleteProject,
  postProject,
  putProject,
  getProjectList,
  getNamesLdap,
  setAPIRegion
}
