import { client } from './apiHelper'

let __region = process.env.REACT_APP_REGION

const setAPIRegion = region => {
  __region = region
}

const getProjectInformation = (jobId, body) => {
  return client.post(`/source/information/information?jobId=${jobId}`, body, { region: __region })
}

const updateProjectInformation = (projectId, body) => {
  return client.put(`/source/information/information?projectId=${projectId}`, body, { region: __region })
}

export default {
  setAPIRegion,
  getProjectInformation,
  updateProjectInformation
}
