import sourcesApi from '../objects/sourcesAPI'
import referenceApi from '../objects/referenceApi'
import stepFunctionsApi from '../objects/stepFunctionsApi'
import projectApi from '../objects/projectApi'
import apiHelper from '../objects/apiHelper'
import stepFunctionStatusLoop from './common/stepFunctionStatusLoop'
import { clearedEditorAction } from './common/query'
import { executedMergeAction } from './common/join'
import { loadData as projectLoadData, setProjectFileDirtyFlag } from './project/async'
import { savedSynonymDialogAction } from './common/synonymDialog'
import { QUERY_TYPE } from '../../queryType'

import {
  executedWorfklowAction,
  executingSavedWorkflowAction,
  savedWorkflowAction,
  updatedMergeReferenceAction
} from './common/workflow'

const ACTIONS = {
  SET_LOADED_QUERY: 'SET_LOADED_QUERY',
  CLEAR_LOADED_QUERIES: 'CLEAR_LOADED_QUERIES'
}

const INITIAL_STATE = {
  loadedSourceData: false,
  queryDrawerOpen: true,
  hideForm: false,
  visibleView: 'query',
  resultsView: undefined,
  file: {
    columns: []
  },
  files: [],
  queries: [],
  workflows: [],
  loadQuery: false,
  loadedSavedItems: false,
  loadedQueries: {
    [QUERY_TYPE.QUERY]: null,
    [QUERY_TYPE.WORKFLOW]: null,
    [QUERY_TYPE.UPDATE]: null,
    [QUERY_TYPE.MERGE]: null,
    [QUERY_TYPE.NEW_COLUMN]: null,
    [QUERY_TYPE.REMOVE_ROWS]: null,
    [QUERY_TYPE.AGGREGATE]: null
  },
  mergeData: {
    isLoading: false,
    fileColumns: [],
    referenceTable: {},
    referenceColumns: [],
    joinType: '',
    joinList: []
  },
  referenceTables: [],
  referenceColumns: [],
  saveQueryDialog: {
    isSaving: false,
    open: false,
    guid: undefined,
    name: '',
    queryType: ''
  },
  refreshData: {
    message: undefined,
    isRunning: false,
    isFinished: false,
    hideButtonBar: false
  },
  logs: {
    isLoaded: false,
    isLoading: false,
    ScannedCount: 0,
    Items: []
  },
  newColumn: {
    name: '',
    type: '',
    formula: null,
    flag: false,
    isLoading: false,
    resultsGrid: {
      isLoading: false,
      columns: [],
      data: [],
      count: 0
    },
    message: '',
    refreshData: false
  },
  aggregators: {
    selectedAggregators: [],
    fieldsAgg: [],
    isLoading: false,
    resultsGrid: {
      isLoading: false,
      columns: [],
      data: [],
      count: 0
    },
    message: '',
    refreshData: false
  }
}

export const resetDataMessageAction = {
  type: 'dataPrep/RESET_DATA_MESSAGE'
}

export const changedDataPrepRegionAction = {
  type: 'dataPrep/CHANGED_REGION'
}

export const setSubNavigationAction = {
  type: 'dataPrep/SET_SUB_NAVIGATION'
}

export const setResultsNavigationAction = {
  type: 'dataPrep/SET_RESULTS_NAVIGATION'
}

export const toggledQueryOpenAction = {
  type: 'dataPrep/TOGGLED_QUERY_OPEN'
}

export const setBarFilterAction = {
  type: 'dataPrep/SET_BAR_FILTER'
}

export const editFormulaDialogAction = {
  type: 'dataPrep/EDIT_FORMULA_DIALOG'
}

export const saveFormulaDialogAction = {
  type: 'dataPrep/SAVE_FORMULA_DIALOG'
}

export const toggleSaveQueryDialogAction = {
  type: 'dataPrep/TOGGLE_SAVE_QUERY_DIALOG'
}

export const setNameSaveQueryDialogAction = {
  type: 'dataPrep/SET_NAME_SAVE_QUERY_DIALOG'
}

export const setDescriptionSaveQueryDialogAction = {
  type: 'dataPrep/SET_DESCRIPTION_SAVE_QUERY_DIALOG'
}

export const saveQueryNewColAction = {
  type: 'dataPrep/SAVE_QUERY_NEW_COL'
}

export const saveQueryAggregationAction = {
  type: 'dataPrep/SAVE_QUERY_AGGREGATION'
}

export const savingQueryDialogAction = {
  type: 'dataPrep/SAVING_QUERY_DIALOG'
}

export const savedQueryDialogAction = {
  type: 'dataPrep/SAVED_QUERY_DIALOG'
}

export const savedQueryMergeAction = {
  type: 'dataPrep/SAVED_QUERY_MERGE'
}

export const loadedSavedItemsAction = {
  type: 'dataPrep/LOADED_SAVED_ITEMS'
}

export const openQueryAction = {
  type: 'dataPrep/OPEN_QUERY'
}

export const openWorkflowAction = {
  type: 'dataPrep/OPEN_WORKFLOW'
}

export const deleteQueryAction = {
  type: 'dataPrep/DELETE_QUERY'
}

export const deleteWorkflowAction = {
  type: 'dataPrep/DELETE_WORKFLOW'
}

export const loadedUserReferenceTablesAction = {
  type: 'dataPrep/LOADED_USER_REFERENCE_TABLES'
}

export const mergingDataAction = {
  type: 'dataPrep/MERGING_DATA'
}

export const mergedDataAction = {
  type: 'dataPrep/MERGED_DATA'
}

export const resetRefreshDataAction = {
  type: 'dataPrep/RESET_REFRESH_DATA'
}

export const profiledDataAction = {
  type: 'dataPrep/PROFILED_DATA'
}

export const searchingLogsAction = {
  type: 'dataPrep/SEARCHING_LOGS'
}

export const searchedLogsAction = {
  type: 'dataPrep/SEARCHED_LOGS'
}

export const changedNewColumnAction = {
  type: 'dataPrep/CHANGED_NEW_COLUMN'
}

export const executingNewColumnAction = {
  type: 'dataPrep/EXECUTING_NEW_COLUMN'
}

export const executedNewColumnAction = {
  type: 'dataPrep/EXECUTED_NEW_COLUMN'
}

export const setRefreshDataAction = {
  type: 'dataPrep/SET_REFRESH_DATA'
}

export const clearNewColumnAction = {
  type: 'dataPrep/CLEAR_NEW_COLUMN'
}

export const selectAggregatorsAction = {
  type: 'dataPrep/SELECT_AGGREGATORS'
}

export const selectedFieldAggregationAction = {
  type: 'dataPrep/SELECTED_FIELD_AGGREGATION'
}

export const removedFieldAggregationAction = {
  type: 'dataPrep/REMOVED_FIELD_AGGREGATION'
}

export const executingAggregatorsAction = {
  type: 'dataprep/EXECUTING_AGGREGATORS'
}

export const executedAggregatorsAction = {
  type: 'dataPrep/EXECUTED_AGGREGATORS'
}

export const filesWereLoadedAction = {
  type: 'dataPrep/FILES_WERE_LOADED'
}

export const setLoadedQuery = query => {
  return {
    type: ACTIONS.SET_LOADED_QUERY,
    payload: query
  }
}

export const clearLoadedQueries = () => {
  return {
    type: ACTIONS.CLEAR_LOADED_QUERIES
  }
}

const notCommonFormat = file => {
  return file.type !== 'I' && file.type !== 'T' && file.type !== 'FORMAT'
}

const getAggValue = (agg, field) => {
  return agg.__isNew__ === true
    ? `${agg.value} as '${field}'`
    : `${agg.value}(${field}) as '${field}'`
}

export const saveQueryNewCol = async (dispatch, getState) => {
  const {
    dataPrep: {
      newColumn: { name, formula },
      saveQueryDialog: { description, guid = null, name: nameDialog, open },
      visibleView: queryType = 'query'
    },
    navigation: { file: { fileType } },
    project: { id: projectId },
    user: { ownerId: userId }
  } = getState()

  const queries = await sourcesApi.saveQueryNewCol(userId, {
    projectId,
    guid,
    name: nameDialog,
    queryType,
    fileType,
    formula,
    nameNewCol: name,
    description
  })

  return dispatch({
    ...saveQueryNewColAction,
    payload: {
      queries,
      saveQueryDialog: {
        open: !open,
        isSaving: false,
        guid: undefined,
        name: '',
        description: ''
      }
    }
  })
}

export const saveQueryAggregation = async (dispatch, getState) => {
  const {
    dataPrep: { aggregators: { selectedAggregators, fieldsAgg }, saveQueryDialog: { guid = null, description, open, name: nameDialog }, visibleView: queryType = 'query' },
    navigation: { file: { fileType } },
    project: { id: projectId },
    user: { ownerId: userId }
  } = getState()

  const queries = await sourcesApi.saveQueryAggregation(userId, {
    projectId,
    guid,
    name: nameDialog,
    queryType,
    fileType,
    selectedAggregators,
    fieldsAgg,
    description
  })

  return dispatch({
    ...saveQueryNewColAction,
    payload: {
      queries,
      saveQueryDialog: {
        open: !open,
        isSaving: false,
        guid: undefined,
        name: '',
        description: ''
      }
    }
  })
}

export const saveQueryDialog = async (dispatch, getState) => {
  const {
    dataPrep: {
      saveQueryDialog: { guid = null, open, name, description },
      visibleView: queryType = 'query'
    },
    navigation: { file: { fileType } },
    project: { id: projectId },
    query: { querySelectFields, queryWhereConditions },
    update: { deleteWhereConditions, updateSetConditions, updateWhereConditions },
    user: { ownerId: userId }
  } = getState()

  dispatch(savingQueryDialogAction)

  const getWhereSelectSet = () => {
    switch (queryType) {
      case 'query':
        return {
          selectSet: querySelectFields,
          where: queryWhereConditions
        }
      case 'removeRows':
        return {
          where: deleteWhereConditions
        }
      default:
        return {
          selectSet: updateSetConditions,
          where: updateWhereConditions
        }
    }
  }

  const { selectSet = '', where } = getWhereSelectSet()

  const queries = await sourcesApi.saveQuery(userId, {
    projectId,
    guid,
    name,
    queryType,
    fileType,
    selectSet,
    where,
    description
  })

  return dispatch({
    ...savedQueryDialogAction,
    payload: {
      queries,
      saveQueryDialog: {
        open: !open,
        isSaving: false,
        guid: undefined,
        name: '',
        description: ''
      }
    }
  })
}

export const saveQuery = query => async (dispatch, getState) => {
  const {
    user,
    dataPrep
  } = getState()

  const previousGuids = dataPrep.queries.map(query => query.guid)

  dispatch(savingQueryDialogAction)

  const queries = await sourcesApi.saveQuery(user.ownerId, query)

  dispatch({
    ...loadedSavedItemsAction,
    payload: {
      queries
    }
  })

  return query.guid
    ? queries.find(item => item.guid === query.guid)
    : queries.find(item => !previousGuids.includes(item.guid))
}

export const saveQueryMerge = async (dispatch, getState) => {
  const {
    dataPrep: {
      mergeData: { fileColumns, referenceTable, referenceColumns, joinType },
      saveQueryDialog: { guid = null, description, name: nameDialog }
    },
    join: { joinList, whereList },
    navigation: { file: { fileType } },
    project: { id: projectId },
    user: { ownerId: userId }
  } = getState()

  const queries = await sourcesApi.saveQueryMerge(userId, {
    projectId,
    guid,
    name: nameDialog,
    queryType: 'merge',
    fileType,
    fileColumns,
    referenceTable,
    referenceColumns,
    joinType,
    joinList,
    whereList,
    description
  })

  dispatch({
    ...savedQueryMergeAction,
    payload: {
      join: {
        isLoading: false
      },
      dataPrep: {
        queries,
        mergeData: {
          isLoading: false,
          fileColumns,
          referenceTable,
          referenceColumns,
          joinType,
          joinList
        },
        saveQueryDialog: {
          isSaving: false,
          guid: undefined,
          name: '',
          description: ''
        },
        queryDrawerOpen: true
      }
    }
  })
}

export const loadSavedItems = async (dispatch, getState) => {
  const {
    global: { isLoaded },
    project: { id: projectId },
    user: { ownerId: userId }
  } = getState()

  if (isLoaded) {
    const queries = await sourcesApi.getSavedQueries(userId)
    const workflows = await sourcesApi.getSavedWorkflows(userId)

    return dispatch({
      ...loadedSavedItemsAction,
      payload: {
        projectId,
        queries,
        workflows,
        loadQuery: true,
        loadedSavedItems: true,
        saveQueryDialog: {
          isSaving: false,
          guid: undefined,
          name: ''
        }
      }
    })
  }
}

export const openQuery = guid => async (dispatch, getState) => {
  const { dataPrep: { queries } } = getState()
  const queryToOpen = queries.find(x => x.guid === guid)

  const initialWhereState = {
    columnName: '',
    operation: '=',
    whereValue: '',
    orColumnName: '',
    orOperation: '=',
    orWhereValue: ''
  }

  const joinList = queryToOpen.queryType === 'merge' ? queryToOpen.joinList : []

  return dispatch({
    ...openQueryAction,
    payload: {
      dataPrep: {
        aggregators: {
          selectedAggregators: queryToOpen.queryType === 'aggregate' ? queryToOpen.selectedAggregators : [],
          fieldsAgg: queryToOpen.queryType === 'aggregate' ? queryToOpen.fieldsAgg : []
        },
        visibleView: queryToOpen.queryType,
        mergeData: {
          fileColumns: queryToOpen.queryType === 'merge' ? queryToOpen.fileColumns : [],
          referenceTable: queryToOpen.queryType === 'merge' ? queryToOpen.referenceTable : {},
          referenceColumns: queryToOpen.queryType === 'merge' ? queryToOpen.referenceColumns : [],
          joinType: queryToOpen.queryType === 'merge' ? queryToOpen.joinType : '',
          joinList
        },
        saveQueryDialog: {
          open: false,
          isSaving: false,
          guid: queryToOpen.guid,
          name: queryToOpen.name,
          description: queryToOpen.description,
          queryType: queryToOpen.queryType
        },
        newColumn: {
          name: queryToOpen.queryType === 'newColumn' ? queryToOpen.nameNewCol : '',
          formula: queryToOpen.queryType === 'newColumn' ? queryToOpen.formula : ''
        }
      },
      join: {
        joinList,
        whereList: queryToOpen.queryType === 'merge' ? queryToOpen.whereList : []
      },
      query: {
        querySelectFields: queryToOpen.queryType === 'query' ? queryToOpen.selectSet : [{ value: '*', label: '* (all)' }],
        queryWhereConditions: queryToOpen.queryType === 'query' ? [...queryToOpen.where] : [],
        loadSave: false
      },
      update: {
        updateSetConditions: queryToOpen.queryType === 'update' ? queryToOpen.selectSet : [{ columnName: '', operation: '=', whereValue: '' }],
        updateWhereConditions: queryToOpen.queryType === 'update' ? queryToOpen.where : [{ ...initialWhereState }],
        deleteWhereConditions: queryToOpen.queryType === 'removeRows' ? queryToOpen.where : [{ ...initialWhereState }]
      }
    }
  })
}

export const deleteQuery = guid => async (dispatch, getState) => {
  const { user: { ownerId } } = getState()
  const queries = await sourcesApi.deleteQuery(ownerId, guid)
  return dispatch({
    ...deleteQueryAction,
    payload: { queries }
  })
}

export const deleteWorkflow = guid => async (dispatch, getState) => {
  const { user: { ownerId } } = getState()
  const workflows = await sourcesApi.deleteWorkflow(ownerId, guid)
  return dispatch({ ...deleteWorkflowAction, payload: { workflows } })
}

export const loadUserReferenceTables = async (dispatch, getState) => {
  const { global: { isLoaded }, project: { country, id }, user: { ownerId } } = getState()
  if (isLoaded) {
    const { region } = apiHelper.getCountry(country)

    const referenceTables = await referenceApi.searchReferenceTable({
      ownerId,
      region
    })

    return dispatch({
      ...loadedUserReferenceTablesAction,
      payload: { projectId: id, referenceTables }
    })
  }
}

const getSelectedItemColumns = async (project, dataPrep, menuItem) => {
  const fileData = project.fileData.find(file => file.guid === menuItem.value)

  if (fileData) {
    return {
      referenceColumns: fileData.columns,
      mergeData: {
        ...dataPrep.mergeData,
        isLoading: false,
        referenceTable: {
          tableName: fileData.guid,
          value: project.id,
          label: menuItem.label
        }
      }
    }
  }

  const sourceData = await sourcesApi.getSourceDataOnly(menuItem.value)

  return {
    referenceColumns: sourceData[0].columns,
    mergeData: {
      ...dataPrep.mergeData,
      isLoading: false,
      referenceTable: {
        ...menuItem,
        tableName: sourceData[0].guid
      }
    }
  }
}

export const updateMergeReference = (key, value) => async (dispatch, getState) => {
  const {
    project,
    dataPrep
  } = getState()

  if (key === 'referenceTable') {
    dispatch(mergingDataAction)

    /*
     * One of the 2 places for changing regions in the UI.
     *   1. When loading project data, which happens for any screen.
     *   2. When utilizing a reference table, where the metadata for the table contains information on which region is applicable.
     *      - note: this only happens to apply to the 'sourcesApi', since it should be the only interface point to the regional reference data
     */

    const { region } = apiHelper.getCountry(project.country)
    sourcesApi.setAPIRegion(region)

    return dispatch({
      ...mergedDataAction,
      payload: await getSelectedItemColumns(project, dataPrep, value)
    })
  } else {
    return dispatch({
      ...mergedDataAction,
      payload: {
        mergeData: {
          ...dataPrep.mergeData,
          [key]: value
        }
      }
    })
  }
}

export const resetFileDataState = async (dispatch, getState) => {
  const { project: { id } } = getState()
  dispatch(projectLoadData(id, true))
  return dispatch(resetRefreshDataAction)
}

const dispatchResetDataMessage = (dispatch, message, isRunning, isFinished) =>
  dispatch({
    ...resetDataMessageAction,
    payload: {
      refreshData: {
        message,
        isRunning,
        isFinished,
        hideButtonBar: true
      }
    }
  })

const dispatchUpdateFileResetFail = dispatch =>
  dispatch({
    ...resetDataMessageAction,
    payload: {
      refreshData: {
        message: 'Profiling Failed',
        isRunning: false,
        isFinished: true,
        hideButtonBar: false
      }
    }
  })

const checkProfilingStatus = async (dispatch, executionArn, projectId, guid) => {
  const moveOnAfterProfiling = async arn => {
    const project = await projectApi.getProject(projectId)

    dispatch({
      ...profiledDataAction,
      payload: {
        global: { isReadyToRedirect: true }
      }
    })

    const currentFile = project.files.find(x => x.guid === guid)

    currentFile.profileStatus = arn.status
    currentFile.processingFinished = new Date().toISOString()
    currentFile.isProcessing = false

    await projectApi.putProject(project)

    return arn.status === 'SUCCEEDED'
      ? dispatchResetDataMessage(dispatch, 'Profiling was Successful', false, true)
      : dispatchUpdateFileResetFail(dispatch)
  }

  return stepFunctionStatusLoop(
    executionArn,
    ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
    moveOnAfterProfiling
  )
}

export const refreshFileData = async (dispatch, getState) => {
  const {
    navigation: { file: { guid } },
    project,
    project: { country, files, id: projectId }
  } = getState()

  /*
   * One of the 2 places for changing regions in the UI.
   *   1. When loading project data, which happens for any screen.
   *   2. When utilizing a reference table, where the metadata for the table contains information on which region is applicable.
   *      - note: this only happens to apply to the 'sourcesApi', since it should be the only interface point to the regional reference data
   */

  const { region } = apiHelper.getCountry(country)

  sourcesApi.setAPIRegion(region)
  dispatchResetDataMessage(dispatch, 'Removing Current Data Set', true, false)

  const { executionArn } = await sourcesApi.deleteSource(projectId, guid, false)

  const moveOnAfterSourceDelete = async arn => {
    if (arn.status === 'SUCCEEDED') {
      dispatchResetDataMessage(dispatch, 'Data Deleted Profiling Started', true, false)

      const currentFile = files.find(x => x.guid === guid)
      const format = currentFile.format === 'txt' ? 'text' : currentFile.format
      const s3Key = `${projectId}/${guid}/${currentFile.path}`
      const s3Bucket = apiHelper.getBucketName(country)

      const step = await stepFunctionsApi.profileData({
        id: projectId,
        guid,
        doProfileData: true,
        reloadFile: true,
        format,
        s3Bucket,
        s3Key,
        s3Path: `${s3Bucket}/${s3Key}`
      })

      currentFile.executionArn = step.executionArn
      currentFile.profileStatus = 'Profiling'
      currentFile.processingFinished = undefined
      currentFile.isProcessing = true

      await projectApi.putProject({ ...project, files })

      dispatchResetDataMessage(dispatch, 'Profiling Running', true, false)

      return checkProfilingStatus(dispatch, step.executionArn, projectId, guid)
    } else {
      return dispatchUpdateFileResetFail(dispatch)
    }
  }

  return stepFunctionStatusLoop(
    executionArn,
    ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
    moveOnAfterSourceDelete
  )
}

export const searchLogs = searchFilter => async (dispatch, getState) => {
  const {
    navigation: { file: { guid } }
  } = getState()

  dispatch({
    ...searchingLogsAction,
    payload: {
      logs: {
        isLoading: true,
        Items: []
      }
    }
  })

  const type = searchFilter == null ? null : searchFilter.value
  const results = await sourcesApi.searchLogs(guid, type)

  return dispatch({
    ...searchedLogsAction,
    payload: {
      logs: {
        ...results,
        isLoading: false
      }
    }
  })
}

export const changedNewColumn = (field, value) => (dispatch, getState) => {
  const {
    dataPrep: { newColumn }
  } = getState()

  dispatch({
    ...changedNewColumnAction,
    payload: {
      dataPrep: {
        newColumn: {
          ...newColumn,
          [field]: value
        }
      }
    }
  })
}

const getOutput = (status, output) =>
  status === 'SUCCEEDED' && output.id
    ? {
        refreshData: true,
        message: 'Column successfully added'
      }
    : {
        refreshData: false,
        message: output?.Error || 'Error trying to add new column'
      }

export const executeNewColumn = (id, tableName) => async (dispatch, getState) => {
  const {
    dataPrep: { newColumn },
    navigation: { file },
    project: { country }
  } = getState()

  /*
   * One of the 2 places for changing regions in the UI.
   *   1. When loading project data, which happens for any screen.
   *   2. When utilizing a reference table, where the metadata for the table contains information on which region is applicable.
   *      - note: this only happens to apply to the 'sourcesApi', since it should be the only interface point to the regional reference data
   */

  const { region } = apiHelper.getCountry(country)

  sourcesApi.setAPIRegion(region)

  dispatch({
    ...executingNewColumnAction,
    payload: {
      newColumn: {
        ...newColumn,
        isLoading: true
      }
    }
  })

  await setProjectFileDirtyFlag(file.guid, true)

  try {
    const { executionArn } = await sourcesApi.startNewColumn({
      id,
      guid: tableName,
      newName: newColumn.name.value,
      formula: newColumn.formula.value
    })

    const moveOnAfterNewColumn = async res => {
      const parsedOutput = JSON.parse(res.output)
      const formattedOutput = getOutput(res.status, parsedOutput)

      return dispatch({
        ...executedNewColumnAction,
        payload: {
          newColumn: {
            ...newColumn,
            ...formattedOutput,
            isLoading: false
          }
        }
      })
    }

    return stepFunctionStatusLoop(
      executionArn,
      ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
      moveOnAfterNewColumn
    )
  } catch (err) {
    return dispatch({
      ...executedNewColumnAction,
      payload: {
        newColumn: {
          ...newColumn,
          isLoading: false,
          message: err ? err.message : 'error'
        }
      }
    })
  }
}

export const executeAggregations = (id, guid) => async (dispatch, getState) => {
  const {
    dataPrep: { aggregators, aggregators: { selectedAggregators, fieldsAgg } },
    navigation: { file: { columns, guid: navGuid } },
    project: { country }
  } = getState()
  /*
   * One of the 2 places for changing regions in the UI.
   *   1. When loading project data, which happens for any screen.
   *   2. When utilizing a reference table, where the metadata for the table contains information on which region is applicable.
   *      - note: this only happens to apply to the 'sourcesApi', since it should be the only interface point to the regional reference data
   */
  const { region } = apiHelper.getCountry(country)
  sourcesApi.setAPIRegion(region)

  const filteredAggs = fieldsAgg.filter(agg => selectedAggregators.some(selectedAgg => agg.value === selectedAgg))
  const columnsForPreview = columns.reduce((s, a) => {
    const current = filteredAggs.find(x => x.value === a.Name)
    if (current) {
      s.push({ value: current.aggregate, label: current.aggregate })
    } else {
      s.push({ value: a.Name, label: a.Name })
    }
    return s
  }, [])
  dispatch(executingAggregatorsAction)
  await setProjectFileDirtyFlag(navGuid, true)

  try {
    const { executionArn } = await sourcesApi.startAggregation({
      querySelectFields: columnsForPreview,
      id,
      guid
    })

    const moveOnAfterAggregation = async arn => {
      const output = JSON.parse(arn.output)
      return dispatch({
        ...executedAggregatorsAction,
        payload: {
          aggregators: {
            ...aggregators,
            message: arn.status === 'SUCCEEDED' && output.id ? 'Successfully aggregated' : output,
            isLoading: false
          }
        }
      })
    }

    return stepFunctionStatusLoop(
      executionArn,
      ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
      moveOnAfterAggregation
    )
  } catch (ex) {
    return dispatch({
      ...executedAggregatorsAction,
      payload: {
        aggregators: {
          ...aggregators,
          message: ex ? ex.data.error : 'error',
          isLoading: false
        }
      }
    })
  }
}

export const loadData = async (dispatch, getState) => {
  const {
    global: { isLoaded },
    navigation: { ...navigation },
    project: { files, fileData: fileDataFromState },
    refData: { isLoaded: refIsLoaded }
  } = getState()
  const file = navigation.file
  if (isLoaded && refIsLoaded) {
    const fileData = files
      .filter(file => file.profileStatus === 'SUCCEEDED' && file.src !== 'dataFabric')
      .map(file => {
        const fileData = fileDataFromState.find(y => y.guid === file.guid && y.columns)
        return Object.assign({ ...file }, fileData ? { columns: fileData.columns } : null)
      })
    if (fileData.length === 0) {
      dispatch({
        ...filesWereLoadedAction,
        payload: {
          dataPrep: {
            loadedSourceData: true,
            profileData: [],
            message: 'No files successfully profiled.',
            hideForm: true
          }
        }
      })
    } else {
      dispatch({
        ...filesWereLoadedAction,
        payload: {
          dataPrep: {
            loadedSourceData: true,
            hideForm: false
          },
          navigation: {
            files: fileData,
            file: file && notCommonFormat(file) ? fileData.filter(x => x.guid === file.guid)[0] : fileData[0]
          }
        }
      })
    }
  }
}

export const openWorkflow = guid => async (dispatch, getState) => {
  const {
    dataPrep: { workflows }
  } = getState()
  const workflowToOpen = workflows.find(x => x.guid === guid)
  return dispatch({
    ...openWorkflowAction,
    payload: {
      dataPrep: {
        queryDrawerOpen: true,
        visibleView: workflowToOpen.queryType
      },
      workflow: workflowToOpen
    }
  })
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_LOADED_QUERY:
      return {
        ...state,
        loadedQueries: {
          ...state.loadedQueries,
          [payload.queryType]: payload
        }
      }

    case ACTIONS.CLEAR_LOADED_QUERIES:
      return {
        ...state,
        loadedQueries: {
          [QUERY_TYPE.QUERY]: null,
          [QUERY_TYPE.WORKFLOW]: null,
          [QUERY_TYPE.UPDATE]: null,
          [QUERY_TYPE.MERGE]: null,
          [QUERY_TYPE.NEW_COLUMN]: null,
          [QUERY_TYPE.REMOVE_ROWS]: null,
          [QUERY_TYPE.AGGREGATE]: null
        }
      }

    case filesWereLoadedAction.type:
    case changedNewColumnAction.type:
    case savedSynonymDialogAction.type:
    case updatedMergeReferenceAction.type:
    case savedWorkflowAction.type:
    case executingSavedWorkflowAction.type:
    case executedWorfklowAction.type:
    case openWorkflowAction.type:
      return {
        ...state,
        ...payload.dataPrep
      }

    case savedQueryMergeAction.type:
      return {
        ...state,
        ...payload.dataPrep
      }

    case saveQueryNewColAction.type:
    case saveQueryAggregationAction.type:
    case loadedSavedItemsAction.type:
    case deleteQueryAction.type:
    case deleteWorkflowAction.type:
    case mergedDataAction.type:
    case resetDataMessageAction.type:
    case searchingLogsAction.type:
    case searchedLogsAction.type:
    case executedAggregatorsAction.type:
    case savedQueryDialogAction.type:
    case executingNewColumnAction.type:
    case executedNewColumnAction.type:
      return {
        ...state,
        ...payload
      }

    case setSubNavigationAction.type:
      return {
        ...state,
        visibleView: payload
      }

    case setResultsNavigationAction.type:
      return {
        ...state,
        resultsView: payload
      }

    case toggledQueryOpenAction.type: {
      const { queryDrawerOpen } = state
      return {
        ...state,
        queryDrawerOpen: !queryDrawerOpen
      }
    }

    case setBarFilterAction.type:
      return {
        ...state,
        barFilter: payload
      }

    case clearedEditorAction.type:
      return {
        ...state,
        formulaDialog: {
          open: false,
          target: undefined,
          index: undefined,
          name: '',
          formula: ''
        },
        saveQueryDialog: {
          isSaving: false,
          open: false,
          guid: undefined,
          name: ''
        }
      }

    case editFormulaDialogAction.type: {
      const { formula, name, index, target, key } = payload
      const { formulaDialog: { open } } = state
      return {
        ...state,
        formulaDialog: {
          open: !open,
          target,
          index,
          key,
          name,
          formula
        }
      }
    }

    case toggleSaveQueryDialogAction.type: {
      const { saveQueryDialog } = state
      return {
        ...state,
        saveQueryDialog: {
          ...saveQueryDialog,
          open: !saveQueryDialog.open
        }
      }
    }

    case setNameSaveQueryDialogAction.type:
      return {
        ...state,
        saveQueryDialog: {
          ...state.saveQueryDialog,
          name: payload
        }
      }

    case setDescriptionSaveQueryDialogAction.type:
      return {
        ...state,
        saveQueryDialog: {
          ...state.saveQueryDialog,
          description: payload
        }
      }

    case savingQueryDialogAction.type:
      return {
        ...state,
        isSaving: true
      }

    case openQueryAction.type:
      return {
        ...state,
        queryDrawerOpen: true,
        aggregators: {
          ...state.aggregators,
          ...payload.dataPrep.aggregators
        },
        visibleView: payload.dataPrep.visibleView,
        mergeData: {
          ...state.mergeData,
          ...payload.dataPrep.mergeData
        },
        saveQueryDialog: { ...payload.dataPrep.saveQueryDialog },
        newColumn: {
          ...state.newColumn,
          ...payload.dataPrep.newColumn
        }
      }

    case loadedUserReferenceTablesAction.type:
      return {
        ...state,
        ...payload,
        mergeData: {
          isLoading: false,
          fileColumns: [],
          referenceTable: {},
          referenceColumns: [],
          joinType: '',
          joinList: []
        },
        saveQueryDialog: {
          isSaving: false,
          guid: undefined,
          name: ''
        }
      }

    case mergingDataAction.type:
      return {
        ...state,
        mergeData: {
          ...state.mergeData,
          isLoading: true
        }
      }

    case executedMergeAction.type: {
      const { dataPrep: { projectId } } = payload
      return projectId === null
        ? {
            ...state,
            projectId: null,
            mergeData: { ...INITIAL_STATE.mergeData }
          }
        : state
    }

    case resetRefreshDataAction.type:
      return {
        ...state,
        refreshData: { ...INITIAL_STATE.refreshData }
      }

    case setRefreshDataAction.type: {
      const { refreshData } = payload
      return {
        ...state,
        newColumn: {
          ...state.newColumn,
          refreshData
        }
      }
    }

    case clearNewColumnAction.type:
      return {
        ...state,
        newColumn: { ...INITIAL_STATE.newColumn }
      }

    case selectAggregatorsAction.type: {
      const { selectedAggregators } = payload
      return {
        ...state,
        aggregators: {
          ...state.aggregators,
          selectedAggregators
        }
      }
    }

    case executingAggregatorsAction.type:
      return {
        ...state,
        aggregators: {
          ...state.aggregators,
          isLoading: true
        }
      }

    case selectedFieldAggregationAction.type: {
      const { value, agg } = payload
      const { aggregators: { fieldsAgg, selectedAggregators } } = state

      const aggregators = fieldsAgg.filter(agg => selectedAggregators.some(selectedAgg => agg.value === selectedAgg))

      const exist = aggregators.find(agg => agg.value === value)

      if (exist) {
        exist.agg = agg
        exist.aggregate = getAggValue(agg, value)
      } else {
        aggregators.push({
          value,
          agg,
          aggregate: getAggValue(agg, value)
        })
      }

      return {
        ...state,
        aggregators: {
          ...state.aggregators,
          fieldsAgg: [...aggregators]
        }
      }
    }

    case removedFieldAggregationAction.type: {
      const { remove } = payload
      const { aggregators: { selectedAggregators } } = state
      const rmAgg = selectedAggregators.filter(a => a !== remove)

      return {
        ...state,
        aggregators: {
          ...state.aggregators,
          selectedAggregators: [...rmAgg]
        }
      }
    }

    default:
      return state
  }
}
