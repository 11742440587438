import { client } from './apiHelper'
import delay from '../pages/common/delay'

// TODO: combine all of these into one function passing the required arguments in during invocation to remove duplicative code

const MAX_TRIES = 2

let __region = process.env.REACT_APP_REGION

const setAPIRegion = region => {
  __region = region
}

const getExpVsRiskAmountCheckData = async (projectId, jobId) => {
  const body = {
    check: 'riskHistoryAmount',
    projectId,
    jobId
  }

  let count = 0

  while (true) {
    try {
      return await client.post('/source/redshift', body, { region: __region })
    } catch (error) {
      const message = error.response.data.error
      if (message.includes('does not exist')) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getExpVsRiskCountCheckData = async (projectId, jobId) => {
  const body = {
    check: 'riskHistoryCount',
    projectId,
    jobId
  }
  let count = 0

  while (true) {
    try {
      return await client.post('/source/redshift', body, { region: __region })
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }

      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getPostCalLapseData = async (projectId, jobId) => {
  const body = {
    check: 'table',
    tableName: `redi_${projectId}_postcal_${jobId}.lapse_check`,
    projectId
  }

  let count = 0

  while (true) {
    try {
      return await client.post('/source/redshift', body, { region: __region })
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getPostCalPolicyTrackerData = async (projectId, jobId) => {
  const body = {
    check: 'table',
    tableName: `redi_${projectId}_postcal_${jobId}.policy_tracker_check`,
    projectId
  }

  let count = 0

  while (true) {
    try {
      return await client.post('/source/redshift', body, { region: __region })
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getTerminatingClaimsData = async (projectId, jobId) => {
  const body = {
    check: 'terminatingClaims',
    projectId,
    jobId
  }
  let count = 0

  while (true) {
    try {
      return await client.post('/source/redshift', body, { region: __region })
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getNonTerminatingClaimsData = async (projectId, jobId) => {
  const body = {
    check: 'nonTerminatingClaims',
    projectId,
    jobId
  }
  let count = 0

  while (true) {
    try {
      return await client.post('/source/redshift', body, { region: __region })
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getTerminatingOvsQClaimsData = async (projectId, jobId) => {
  const body = {
    check: 'term_OvsQ',
    projectId,
    jobId
  }

  let count = 0

  while (true) {
    try {
      return await client.post('/source/redshift', body, { region: __region })
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getNonTerminatingOvsQClaimsData = async (projectId, jobId) => {
  const body = {
    check: 'nonTerm_OvsQ',
    projectId,
    jobId
  }
  let count = 0

  while (true) {
    try {
      return await client.post('/source/redshift', body, { region: __region })
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getIbnrData = async (projectId, jobId, isMonthly, aggs) => {
  const body = {
    check: 'ibnr',
    projectId,
    jobId,
    isMonthly,
    aggs
  }
  let count = 0

  while (true) {
    try {
      return await client.post('/source/redshift', body, { region: __region })
    } catch (error) {
      const message = error.response.data.error
      if (message.includes('does not exist')) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

export default {
  getPostCalLapseData,
  getExpVsRiskCountCheckData,
  getExpVsRiskAmountCheckData,
  getPostCalPolicyTrackerData,
  getTerminatingClaimsData,
  getNonTerminatingClaimsData,
  getTerminatingOvsQClaimsData,
  getNonTerminatingOvsQClaimsData,
  setAPIRegion,
  getIbnrData
}
