import { fetchAuthSession } from 'aws-amplify/auth'
import countries from '../../store/objects/data/countries'

const DOMAIN_SUFFIX = process.env.REACT_APP_RGAENV !== 'cust'
  ? process.env.REACT_APP_RGAENV
  : ''

export const DOMAIN_NAME = [
  'rgare',
  DOMAIN_SUFFIX,
  'redi.cloud'
].filter(item => item).join('-')

const DEFAULT_COUNTRY = {
  value: 'US',
  label: 'United States',
  region: 'us-east-1'
}

async function fetchAuthToken () {
  const session = await fetchAuthSession()
  const token = session.tokens.idToken

  return `Bearer ${token}`
}

async function getHeader () {
  const token = await fetchAuthToken()
  return {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  }
}

function getCountry (code) {
  return countries.find(x => x.value === code) || DEFAULT_COUNTRY
}

function getBucketName (countryCode) {
  const ENV = process.env.REACT_APP_RGAENV
  const country = getCountry(countryCode)

  return `redi-projects-${ENV}-${country.region}.red-rgare.com`
}

async function getWebsocketUrl (countryCode) {
  const ENV = process.env.REACT_APP_RGAENV
  const token = await fetchAuthToken()
  const country = getCountry(countryCode)
  const prefix = `api-websocket-${country.region}`
  const suffix = ENV !== 'cust' ? `-${ENV}` : ''
  const address = `wss://${prefix}.rgare${suffix}-redi.cloud`
  const querystring = `Authorization=${token}`

  return `${address}?${querystring}`
}

async function callApi (method, route, opts = {}) {
  const body = opts.body || undefined
  const header = await getHeader()
  const querystring = new URLSearchParams(opts.query || {}).toString()
  const subRegion = opts.region ? `-${opts.region}` : ''
  const baseUrl = `https://api${subRegion}.${DOMAIN_NAME}`
  const uri = [baseUrl + route, querystring].filter(item => item).join('?')

  const res = await fetch(uri, {
    method,
    ...header,
    body: JSON.stringify(body)
  })

  if (!res.ok) {
    const error = await res.text()
    throw new Error(error)
  }

  if (opts.parseAsText) {
    const text = await res.text()
    if (text.includes('{')) {
      return JSON.parse(text)
    } else {
      return text
    }
  }
  if (opts.isBodyNull) {
    return res
  } else {
    return await res.json()
  }
}

export const client = {
  get: (uri, opts) => callApi('GET', uri, opts),
  post: (uri, body, opts) => callApi('POST', uri, { ...opts, body }),
  put: (uri, body, opts) => callApi('PUT', uri, { ...opts, body }),
  delete: (uri, body, opts) => callApi('DELETE', uri, { ...opts, body })
}

export default {
  getBucketName,
  getCountry,
  getWebsocketUrl,
  client
}
