import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Grid from '@mui/material/Grid'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { updatedCurrentCalculationTabAction } from '../store/pages/calculation/actions'

const CalculationNavigation = () => {
  const location = useLocation()
  const currentTab = useSelector((store) => store.calculation.currentTab)
  const dispatch = useDispatch()
  const renderNavTabs = location.pathname.indexOf('calculation') === 1

  if (!renderNavTabs) {
    return ''
  }
  const getTabs = () => {
    return [
      { name: 'Parameters' },
      { name: 'Aggregators' },
      { name: 'Expected Bases' },
      { name: 'IBNR' },
      { name: 'Run Study' }
    ]
  }

  return (
    <Grid container >
      <Grid item xs={12}>
        <Tabs
          data-testid='tabs-calculation'
          scrollButtons={false}
          value={currentTab}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          onChange={(e, newVal) => dispatch({
            ...updatedCurrentCalculationTabAction,
            payload: newVal
          })}
        >
          {
            getTabs().map((tab, index) =>
              <Tab
                data-testid={`tab-name-${index}`}
                key={tab.name}
                value={tab.name}
                label={tab.name}
              >
              </Tab>
            )
          }
        </Tabs>
      </Grid>
    </Grid>
  );
}

export default CalculationNavigation
