import './index.css'
import React from 'react'
import App from './containers/app'
import RediTheme from './themes/rediTheme'
import { store } from './store'
import { Amplify } from 'aws-amplify'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { LicenseManager } from 'ag-grid-enterprise'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-064958}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{RGA_Enterprise_Services_Company}_is_granted_a_{Multiple_Applications}_Developer_License_for_{7}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{1_September_2025}____[v3]_[01]_MTc1NjY4MTIwMDAwMA==a28c11e9a767405f38630d7a55701924')

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
      userPoolId: process.env.REACT_APP_USERPOOLID,
      userPoolClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID,
      region: process.env.REACT_APP_REGION,
      rgaEnv: process.env.REACT_APP_RGAENV,
      mandatorySignIn: true,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_DOMAIN,
          scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [`${window.location.origin}/`],
          redirectSignOut: [`${window.location.origin}/`],
          responseType: 'code',
          idProvider: 'okta'
        }
      }
    }
  }
})

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={RediTheme}>
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
            </LocalizationProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>
)
