import { client } from './apiHelper'

let __region = process.env.REACT_APP_REGION

const setAPIRegion = region => {
  __region = region
}

const getAPIRegion = () => {
  return __region
}

const getPreCalCheck = projectId => {
  return client.get(`/source/precalcheck/precalcheck?projectId=${projectId}`, { region: __region })
}

const runRediCCFT = body => {
  return client.post('/engineformatting', body, { region: __region })
}

const runFixPrecalChecks = body => {
  return client.post('/cal/precalfix', body, { region: __region })
}

export default {
  setAPIRegion,
  getAPIRegion,
  getPreCalCheck,
  runRediCCFT,
  runFixPrecalChecks
}
