const { REACT_APP_RGAENV: rgaEnv } = process.env

export const signInAction = {
  type: 'user/SIGN_IN'
}

const initialState = {
  ownerId: ''
}

export const getRole = groups => {
  if ((['poc', 'sdlc'].includes(rgaEnv) && groups.includes('REDI NP Admin Team')) ||
    groups.includes('REDI Admin Team')) {
    return 'admin'
  }
  return null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case signInAction.type: {
      const {
        'custom:groups': adGroups = [],
        email: ownerId,
        family_name: familyName,
        given_name: givenName,
        preferred_username: sidemail
      } = payload
      const sid = sidemail.split('@')[0]
      const names = givenName.split(' ')
      const fName = names[names.length - 1]

      try {
        return {
          ...state,
          ownerId,
          ownerName: `${fName} ${familyName}`,
          sid,
          role: getRole(JSON.parse(adGroups))
        }
      } catch (ex) {
        // This is a hack to fix an issue with cognito
        const roles = adGroups.length > 0 ? adGroups.split(',').map(x => x.trim()).map(x => decodeURI(x)) : []
        return {
          ...state,
          ownerId,
          sid,
          role: getRole(roles)
        }
      }
    }
    default:
      return state
  }
}
