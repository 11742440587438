import apiHelper, { client } from './apiHelper'

const fetchDataProducts = async (userId, country) => {
  const { region } = apiHelper.getCountry(country)

  const dataProducts = process.env.REACT_APP_RGAENV !== 'poc'
    ? client.get(`/edp/data_products/${userId}`, {
      region
    })
    : []
  return dataProducts
}

const fetchViews = async (dataproduct, country) => {
  if (!dataproduct) {
    return []
  }

  const currentDP = dataproduct.value.toUpperCase()
  const { region } = apiHelper.getCountry(country)
  const edpViews = process.env.REACT_APP_RGAENV !== 'poc'
    ? client.get(`/edp/views/${currentDP}`, {
      region
    })
    : []
  return edpViews
}

const fetchColumns = async (dataproduct, viewName, country) => {
  const { region } = apiHelper.getCountry(country)
  const edpViewColumns = process.env.REACT_APP_RGAENV !== 'poc'
    ? client.get(`/edp/datatypes?database=UAT_ACC&schema=${dataproduct.value}&name=${viewName.fileName}`,
      {
        region
      })
    : []
  return edpViewColumns
}

export default {
  fetchDataProducts,
  fetchViews,
  fetchColumns
}
