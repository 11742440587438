import { client } from './apiHelper'

let __region = process.env.REACT_APP_REGION

const setAPIRegion = region => {
  __region = region
}

const profileData = body => {
  return client.post('/dataprofile', { inputParams: body }, { region: __region })
}

const stepGetStatus = arn => {
  return client.get(`/step/${arn}`, { region: __region })
}

const stepGetStatusByRegion = (arn, region) => {
  return client.get(`/step/${arn}`, { region: __region })
}

// NEW - functions that use the API client

function waitForCompletion (executionArn, opts = {}) {
  const ms = opts.ms || 5000

  return new Promise((resolve, reject) => {
    const handle = setInterval(async () => {
      const result = await stepGetStatus(executionArn)

      if (result.status !== 'RUNNING') {
        clearInterval(handle)

        switch (result.status) {
          case 'SUCCEEDED':
          case 'ABORTED':
            return resolve(result)

          case 'FAILED':
            return reject(new Error(result.error))

          default:
            return reject(new Error(`Invalid status: ${result.status}`))
        }
      }
    }, ms)
  })
}

export default {
  profileData,
  setAPIRegion,
  stepGetStatus,
  stepGetStatusByRegion,
  waitForCompletion
}
