import { client } from './apiHelper'

let __region = process.env.REACT_APP_REGION

const cleanMappings = mappings => {
  const { optionalColumns, ...notOptional } = mappings

  notOptional.columns = notOptional.columns.map(column => {
    const { label, value, ...notThose } = column

    if (label) {
      notThose.source = label
    }

    delete notThose.toolTip

    return notThose
  })

  return notOptional
}

const setAPIRegion = region => {
  __region = region
}

const getMappings = projectId => {
  return client.get(`/source/mappings/mappings?projectId=${projectId}`, { region: __region })
}

const setMappings = async (projectId, guid, mappings) => {
  const sanitizedMappings = await cleanMappings(mappings)

  return client.put(`/source/mappings/mappings?projectId=${projectId}&guid=${guid}`, sanitizedMappings, { region: __region })
}

const removeMappings = projectId => {
  return client.delete(`/source/mappings/mappings?projectId=${projectId}`, { projectId }, { parseAsText: true })
}

const removeMappingsByFile = (projectId, guid) => {
  return client.delete(`/source/mappings/mappings?projectId=${projectId}&guid=${guid}`, { projectId }, { parseAsText: true })
}

export default {
  setAPIRegion,
  getMappings,
  setMappings,
  removeMappings,
  removeMappingsByFile
}
